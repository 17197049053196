<template>
  <div class="agent">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__agentAccountManagement") }}</h2>
      <div
        class="
          d-flex
          align-items-center
          justify-content-between
          mb-3
        "
      >
        <form @submit.prevent="searchAgent">
          <div class="input-group rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchAgentName')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t("__clearSearch") }}
            </button>
          </div>
        </form>
        <button
          @click="showModal()"
          class="ms-3 fs-5 btn btn-primary px-2 rounded-circle lh-sm"
        >
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :tbody-tr-class="rowClass"
            :sort-compare="sortComputed"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div class="status-switch" v-if="isSystemManage && data.item.id !== userInfo.id">
                <input
                  type="checkbox"
                  :id="'switch-' + data.index"
                  v-model="data.item.status"
                  @click="changeStatusAlert(data.item.id)"
                />
                <label :for="'switch-' + data.index"></label>
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
                <button
                  @click="resetPasswordAlert(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__resetPassword')"
                >
                  <i class="bi bi-arrow-counterclockwise fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isEdit ? $t("__editAgent") : $t("__addAgent") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label fw-medium required">{{
                      $t("__agentName")
                    }}</label>
                    <input
                      v-model="modalData.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.name
                      }"
                      id="name"
                      required
                    />
                    <div class="invalid-feedback" v-if="validate && validate.msg.name">
                      <div v-for="(msg, index) in validate.msg.name" :key="index">
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="username"
                      class="form-label fw-medium required"
                      >{{ $t("__agentAccount") }}</label
                    >
                    <input
                      v-model="modalData.username"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.username
                      }"
                      id="username"
                      required
                      :readonly="isEdit"
                    />
                    <div class="invalid-feedback" v-if="validate && validate.msg.username">
                      <div v-for="(msg, index) in validate.msg.username" :key="index">
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="contact_name"
                      class="form-label fw-medium required"
                      >{{ $t("__contactName") }}</label
                    >
                    <input
                      v-model="modalData.contact_name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.contact_name
                      }"
                      id="contact_name"
                      required
                    />
                    <div class="invalid-feedback" v-if="validate && validate.msg.contact_name">
                      <div v-for="(msg, index) in validate.msg.contact_name" :key="index">
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="telephone"
                      class="form-label fw-medium required"
                      >{{ $t("__contactTelephone") }}</label
                    >
                    <input
                      v-model="modalData.telephone"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': validate && validate.msg.telephone
                      }"
                      id="telephone"
                      required
                    />
                    <div class="invalid-feedback" v-if="validate && validate.msg.telephone">
                      <div v-for="(msg, index) in validate.msg.telephone" :key="index">
                        {{ msg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="resetPasswordModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="resetPasswordModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__resetPassword") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__resetPasswordNote", {Agency: modalData.name}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="resetPassword"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="changeStatusModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="changeStatusModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__changeStatus") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{$t("__changeStatusNote", {Agency: modalData.name})}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="changeStatus"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { agent } from '@/http/api/agent.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Agent',
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: i18n.t('__agentName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'username',
          label: i18n.t('__account'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'contact_name',
          label: i18n.t('__contactName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'telephone',
          label: i18n.t('__contactTelephone'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agency_count',
          label: i18n.t('__agencyCount'),
          class: 'align-middle text-center',
          sortable: true
        },
        {
          key: 'device_count',
          label: i18n.t('__deviceCount'),
          class: 'align-middle text-center',
          sortable: true
        },
        {
          key: 'status',
          label: i18n.t('__status'),
          class: 'align-middle text-center',
          sortable: false
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ],
      items: null,
      filter: null,
      filterOn: ['name'],
      search: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      modalData: {
        status: null,
        name: null,
        username: null,
        contact_name: null,
        telephone: null
      },
      validate: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      if (!this.permissions) return false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
    items (data) {
      if (data) {
        this.initBootstrapTooltip()
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    sortComputed (a, b, key, sortDesc) {
      if (sortDesc) {
        if (a.id === this.userInfo.id) {
          return 1
        }
        if (b.id === this.userInfo.id) {
          return -1
        }
      } else {
        if (a.id === this.userInfo.id) {
          return -1
        }
        if (b.id === this.userInfo.id) {
          return 1
        }
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id === this.userInfo.id) return 'text-danger'
    },
    getAgent () {
      const vm = this
      vm.Loading()
      agent
        .get('', vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
            vm.totalRows = vm.items.length
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    searchAgent () {
      this.filter = this.search
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.forEach((item) => {
          if (item.id === id) {
            vm.modalData.name = item.name
            vm.modalData.username = item.username
            vm.modalData.contact_name = item.contact_name
            vm.modalData.telephone = item.telephone
          }
        })
      } else {
        vm.isEdit = false
        vm.modalData.name = null
        vm.modalData.username = null
        vm.modalData.contact_name = null
        vm.modalData.telephone = null
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    submitForm () {
      const vm = this
      vm.Loading()
      if (vm.editModalId) {
        agent
          .update(
            vm.editModalId,
            {
              name: vm.modalData.name,
              contact_name: vm.modalData.contact_name,
              telephone: vm.modalData.telephone
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getAgent()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.validate = res.data.validate
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else {
        agent
          .create(
            {
              username: vm.modalData.username,
              name: vm.modalData.name,
              contact_name: vm.modalData.contact_name,
              telephone: vm.modalData.telephone
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getAgent()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__createSuccess')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
              vm.validate = res.data.validate
            }
            vm.Loaded()
            vm.Alerted()
          })
      }
    },
    resetPasswordAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.resetPasswordModal)
      vm.bootstrap_modal.show()
    },
    resetPassword () {
      const vm = this
      vm.Loading()
      agent.resetPassword(vm.editModalId, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__resetPassword') + i18n.t('__success')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Alerted()
        vm.Loaded()
        vm.bootstrap_modal.hide()
      })
    },
    changeStatusAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.name = item.name
          vm.modalData.status = !item.status
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.changeStatusModal)
      vm.bootstrap_modal.show()
      vm.$refs.changeStatusModal.addEventListener('hidden.bs.modal', () => {
        vm.getAgent()
      })
    },
    changeStatus () {
      const vm = this
      vm.Loading()
      agent.changeStatus(
        vm.editModalId, { status: vm.modalData.status }, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__changeStatus') + i18n.t('__success')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Alerted()
        vm.Loaded()
        vm.bootstrap_modal.hide()
      })
    }
  },
  created () {
    this.getAgent()
  },
  mounted () {}
}
</script>

<style  lang="scss">
</style>
